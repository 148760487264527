import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"
import Footer from "../components/footer"

function compareByLatestDate(a, b) {
  if (a.Date < b.Date) {
    return 1;
  }
  if (a.Date > b.Date) {
    return -1;
  }
  return 0;
}

const TalkPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiTalk {
        nodes {
          Slug
          Title
          Author
          Excerpt
          Background { localFile { url } }
          Thumbnail { localFile { url } }
          Highlight
          Date
        }
      }
    }
  `)
  const articles = data.allStrapiTalk.nodes.sort(compareByLatestDate);

  return <div>
    <Header/>

    <div className="content-pad">
      <h2>talk</h2>
      <p>
        La Fondazione Silene, non ha fini di lucro e persegue finalità di solidarietà sociale, è nel web e tra la gente:
        può essere definita anche attraverso un laboratorio virtuale a cui chiunque lo desideri potrà partecipare con
        idee, progetti e iniziative. È nato così lo spazio talk, che rappresenta un contenitore in cui ciascuno potrà
        seminare il proprio contributo per poi permettere agli altri di assistere al germogliare partecipando alla
        crescita di un progetto forte e rigoglioso.
      </p>
    </div>

    <div className="content-pad white-bg grey-bg" style={{minHeight: '1200px'}}>
      <div className="news-grid">
        {articles.map(a =>
          <a href={'/'+a.Slug} className={a.Highlight ? 'news-block highlight' : 'news-block'}
            style={a.Background && {
              backgroundImage: `url("${a.Background.localFile.url}")`, color: 'white',
            }}
          >
            {a.Thumbnail && <img src={a.Thumbnail.localFile.url}/>}
            <b>{a.Title}</b>
            {!a.Thumbnail && <span className="small-text">{a.Author}</span>}
            {a.Excerpt && !a.Thumbnail && <span>{a.Excerpt}</span>}
          </a>
        )}
      </div>
    </div>

    <Footer/>
  </div>
}

export default TalkPage
